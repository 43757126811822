<template>
  <div class="image-upload">
    <template v-if="fileArr.length > 0">
      <div
        v-for="(item, index) in fileArr"
        :key="item + index"
        class="img_hover"
      >
        <img :src="item">
        <div class="img-img">
          <i class="el-icon-delete" @click="delImg(index)" />
        </div>
      </div>
    </template>
      <!-- :headers="headers" -->
      <!-- :action="uploadUrl" -->
      <!-- :file-list="fileList" -->
    <el-upload 
      action="#"
      v-if="type == 2 || (type == 1 && fileArr.length <= 0)"
      :accept="accept"
      list-type="picture-card"
      :http-request="uploadToCos"
      :on-success="onSuccess"
      :before-upload="beforeUpload"
      :size="size"
      :show-file-list="true"
      :multiple="multiple"
      :on-exceed="onExceed"
      :limit="limit"
      :on-change="onChangeHandle" 
    >
      <i class="el-icon-plus" />
    </el-upload>
  </div>
</template>
<script>
// import * as base64 from 'js-base64/base64.js'
// import { getToken } from '@/utils/auth'
import { uploadObject } from "@/utils/upload-common";
export default {
  name: 'Upload',

  props: {
    // 最大允许上传个数,默认1，type为2时limit必须 >1
    limit: {
      type: Number,
      default: 1
    },
    // 是否支持多选文件，默认true，非必传
    multiple: {
      type: Boolean,
      default: true
    },
    // 接受上传的文件类型，非必传
    accept: {
      type: String,
      default: '.png,.jpg,.jpeg'
    },
    // 限制的文件大小，MB为单位，默认20MB，非必传
    size: {
      type: Number,
      default: 5
    },
    /* 上传组件的类型, 默认1
     ** 1：仅支持上传1张图片，上传成功后把上传按钮隐藏，必须删除已经上传的图片才会出现上传按钮，limt必须为1
     ** 2：支持多张上传，上传成功后图片按顺序排列，需 limt > 1
     */
    type: {
      type: Number,
      default: 1
    },
    // 返回绝对路径path/相对路径url，默认path
    way: {
      type: String,
      default: 'path'
    },
    // 上传完成后返回给父组件的路劲数组
    fileArr: {
      type: Array,
      default: () => {
        return []
      }
    },
  },

  data() {
    return {
      // 上传的请求头部
      /* headers: {
        authentication:
          'USERID ' +
          base64.encode(
            JSON.parse(getToken()).admin.mobile +
              ':' +
              JSON.parse(getToken()).access_token +
              ':' +
              JSON.parse(getToken()).admin.id
          )
      }, */
      uploadUrl: '/api/admin/common/upload', // 上传接口路径
      fileList: []
    }
  },

  methods: {
    onChangeHandle(file, fileList) { // 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
      this.fileList = [file];
      console.log(fileList);
      // console.log('onChangeHandle file, fileList', file, fileList);
      // this.$refs.upload.$refs["upload-inner"].handleClick();
      // console.log("fileList:", fileList); // add
      // this.$emit("fileList", fileList);
    },
    // 上传文件
    uploadToCos() {
      // console.log('uploadToCos uploadFile', this.fileList)
      // debugger
      let files = uploadObject(this.fileList[0].raw, (url) => {
        // this.$emit("input", url);
        console.log(url);
        // this.fileArr.push(url)
        // this.$message.success('上传成功！')
        // this.$emit('getUploadList', this.fileArr)
        this.onSuccess(url)
      });
      console.log(files);
    },


    // 文件上传成功的钩子
    onSuccess(imgUrl, res, file, fileList) {
      console.log(res, file, fileList);
      /* if (res.code !== 200) {
        return this.$message.error(res.message)
      } else { */
        this.fileArr.push(imgUrl)
        this.$message.success('上传成功!')
        this.$emit('getUploadList', this.fileArr)
      // }
    },

    // 文件超出个数限制时的钩子
    onExceed(files, fileList) {
      console.log(files);
      console.log(fileList);
      this.$message({
        type: 'error',
        message: `图片${files[0].name}上传失败，最多只能上传${this.limit}张图片，请先删除再上传！`
      })
    },

    // 上传文件之前的钩子
    beforeUpload(file) {
      return new Promise((resolve, reject) => {
        const isLtSize = this.size ? file.size / 1024 / 1024 < this.size : true
        if (!isLtSize) {
          this.$message({
            type: 'error',
            message: `图片大小超过限制，最大不超过${this.size}MB`
          })
          reject()
        } else {
          resolve(file)
        }
      })
    },

    // 删除已上传图片
    delImg(index) {
      console.log(this.fileArr);
      this.fileArr.splice(index, 1)
      console.log(this.fileArr);
      this.$emit('getUploadList', this.fileArr)


    }
  }
}
</script>

<style lang="scss" scoped>
.image-upload {
  display: flex;
  flex-wrap: wrap;
}
.img-img {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  display: none;
}
.img_hover {
  height: 148px;
  width: 148px;
  display: inline-block;
  position: relative;
  margin-right: 10px;
  vertical-align: top;
  margin-bottom: 10px;
  line-height: 148px;
  overflow: hidden;
  img {
    // height: 148px;
    width: 148px;
  }
}
.img_hover:hover > .img-img {
  display: block;
}
.img-img i {
  cursor: pointer;
  font-size: 26px;
  position: absolute;
  left: 50%;
  top: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
}
</style>
