<template>
  <div class="main">
    <header-t />
    <nav2 />
    <div class="location">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item v-if="this.$route.query.type == 1" :to="{ path: '/register?type=1' }">个人注册</el-breadcrumb-item>
        <el-breadcrumb-item v-if="this.$route.query.type == 2" :to="{ path: '/register?type=2' }">企业注册</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="wrapper entry-container regist-container mb20 shadow">
      <div class="left-container">
        <div class="layui-tab layui-tab-brief">
          <ul class="layui-tab-title">
            <li class="layui-this">
              <a
                href=""
                style="font-size: 16px; color: #000; font-weight: bold"
              >
                {{ type == 1 ? '个人注册' : '企业注册' }}
              </a>
            </li>
          </ul>
        </div>
        <el-form
          ref="loginForm"
          :model="loginForm"
          :rules="loginRules"
          class="form_width"
          label-width="180px"
          size="small"
        >
          <el-form-item prop="phone" label="手机号码：">
            <el-input
              ref="phone"
              v-model.trim="loginForm.phone"
              clearable
              placeholder="请输入手机号码"
              name="phone"
              class="phone"
              tabindex="1"
              autocomplete="on"
              @change="changePhone"
            />
          </el-form-item>
          <el-form-item prop="captcha" label="手机验证码：">
            <el-input
              ref="captcha"
              v-model="loginForm.captcha"
              clearable
              placeholder="输入手机验证码"
              name="captcha"
              tabindex="2"
              class="captcha"
            />
            <el-button
              v-bind:disabled="dis"
              type="primary"
              @click="handlegetBtn"
              style="float:left; margin-left:5px; width: 95px"
            >
              <span v-if="show">发送验证码</span>
              <span v-else>重新发送({{ count }}s)</span>
            </el-button>
          </el-form-item>
          <el-form-item prop="username" label="用户名：">
            <el-input
              ref="username"
              v-model="loginForm.username"
              clearable
              placeholder="请输入用户名"
              name="username"
              class="username"
              tabindex="3"
              autocomplete="on"
            />
          </el-form-item>
          <el-form-item prop="password" label="登录密码：">
            <el-input
              :key="passwordType"
              ref="password"
              v-model="loginForm.password"
              clearable
              :type="passwordType"
              placeholder="请输入登录密码"
              name="password"
              class="password"
              tabindex="4"
              autocomplete="on"
              @keyup.native="checkCapslock"
              @blur="capsTooltip = false"
              @keyup.enter.native="handleLogin"
            />
          </el-form-item>
          <el-form-item  v-if="this.type == 1" label="真实姓名：" prop="name">
            <el-input
              v-model="loginForm.name"
              clearable
              type="text"
              class="username"
              tabindex="5"
              placeholder="请输入真实姓名"
            ></el-input>
          </el-form-item>
          <el-form-item  v-if="this.type == 1" label="身份证：" prop="idCard">
            <el-input
              v-model="loginForm.idCard"
              clearable
              type="text"
              class="username"
              tabindex="6"
              placeholder="请输入身份证号码"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item prop="captcha" label="验证码：">
            <el-input
              ref="captcha"
              v-model="loginForm.captcha"
              clearable
              placeholder="请输入右侧验证码"
              name="captcha"
              class="captcha"
              style="width: 240px"
            />
          </el-form-item> -->
          <template v-if="this.type == 2">
            <el-form-item prop="enterpriseName" label="企业名称：">
              <el-input
                v-model="loginForm.enterpriseName"
                clearable
                placeholder="请输入企业名称"
                tabindex="5"
                class="username"
              />
            </el-form-item>
            <el-form-item prop="creditCode" label="统一社会信用代码：">
              <el-input
                v-model="loginForm.creditCode"
                clearable
                placeholder="请输入统一社会信用代码"
                tabindex="6"
                class="username"
              />
            </el-form-item>
            <el-form-item label="营业执照图片：">
              <div>
                <Image-upload
                  :file-arr="loginForm.img_url"
                  @getUploadList="getLogoList"
                />
                <p class="img-tip">支持JPG\JPEG\PNG格式，不超过5M！
                </p>
              </div>
              <!-- <el-input
              v-model="loginForm.businessLicense"
              clearable
              placeholder="请输入营业执照图片地址"
              tabindex="7"
              class="username w350"
            /> -->
            </el-form-item>
            <el-form-item prop="corporationName" label="企业法人：">
              <el-input
                v-model="loginForm.corporationName"
                clearable
                placeholder="请输入企业法人"
                tabindex="8"
                class="username"
              />
            </el-form-item>
          </template>
          <div class="btn-wrap">
            <el-button
              :loading="loading"
              type="primary"
              class="submit-btn"
              @click.native.prevent="handleLogin"
              >注册</el-button
            >
          </div>
        </el-form>
      </div>
      <!-- <div class="right-container" style="margin: 25px 0 0 0; width: 524px">
        <div class="qr-item mt50" style="margin-top: 0px !important">
          <div class="qr" style="width: 449px">
            <img
              src="https://www.wlmqcol.com/files/system/2020/05-07/1451280ae6b4785643.png"
              alt=""
            />
          </div>
          <div class="title"></div>
        </div>
      </div> -->
    </div>
    <Footer />
  </div>
</template>
  
<script>
import HeaderT from '@/components/HeaderT'
import Nav2 from '@/components/Nav2'
import Footer from '@/components/Footer'
import { isPhone, isId15, isId18 } from '@/utils/validate.js'
import { sendSMS } from '@/api/login'
import ImageUpload from '@/components/Upload/ImageUpload'
export default {
  name: 'Login',
  components: {
    HeaderT,
    Nav2,
    Footer,
    ImageUpload
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请填写账号！'))
      } else if (isPhone(value)) {
        callback(new Error('请输入正确的手机号'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      // 验证长度
      if (value.length < 6 || value.length > 20) {
        callback(new Error('密码必须包含字母数字和特殊字符，且长度应在6至20位之间！'));
        return;
      }

      // 验证是否包含字母
      if (!/[a-zA-Z]/.test(value)) {
        callback(new Error('密码必须包含字母数字和特殊字符，且长度应在6至20位之间！'));
        return;
      }

      // 验证是否包含数字
      if (!/\d/.test(value)) {
        callback(new Error('密码必须包含字母数字和特殊字符，且长度应在6至20位之间！'));
        return;
      }

      // 验证是否包含特殊字符
      // 这里使用的正则表达式匹配常见的特殊字符，您可以根据需要进行调整
      if (!/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value)) {
        callback(new Error('密码必须包含字母数字和特殊字符，且长度应在6至20位之间！'));
        return;
      }

      // 所有条件都满足
      callback();
    }
    const validateCaptcha = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入验证码！'))
      } else {
        callback()
      }
    }
    const validateUserName = (rule, value, callback) => {
      let reg = /^[1]\d{10}$/
      if (!value) {
        callback(new Error('请输入用户名！'))
      } else if (reg.test(value)) {
        callback(new Error('用户名不允许以1开头的11位纯数字！'))
      } else {
        callback()
      }
    }
    const validateEnterpriseName = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入企业名称！'))
      } else {
        callback()
      }
    }
    const validateCreditCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入统一社会信用代码！'))
      } else {
        callback()
      }
    }
    const validateName =  (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入真实姓名！'))
      } else {
        callback()
      }
    }
    const validateIdCard =  (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入身份证号！'))
      } else if(isId15(value) || isId18(value)){
        callback(new Error('请输入正确的身份证号！'))
      } {
        callback()
      }
    }
    /* const validateBusinessLicense = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入营业执照图片地址！"));
      } else {
        callback();
      }
    }; */
    const validateCorporationName = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入企业法人！'))
      } else {
        callback()
      }
    }
    return {
      certificateImage: null, //提交证件图片地址
      type: null, // 注册类型 1个人；2 企业
      loading: false, // 按钮loading
      passwordType: 'password', // 密码框类型
      checked: false, //记住我
      loginForm: {
        phone: '', //手机号
        username: '', // 用户名
        password: '', // 密码
        name: '', //真实姓名
        idCard: '', // 身份证号
        captcha: '', // 验证码
        enterpriseName: '', //企业名称
        creditCode: '', //统一社会信用代码
        businessLicense: '', //营业执照图片地址
        corporationName: '' //企业法人
      },
      dis: true,
      show: true,
      isGrey: false, //按钮样式
      timer: null, //计时器倒计时
      LoginBtn: true,
      count: '',
      radio: '1', //单选框
      // 表单检验规则
      loginRules: {
        phone: [{ required: true, trigger: 'blur', validator: validatePhone }],
        username: [
          { required: true, trigger: 'blur', validator: validateUserName }
        ],
        password: [
          { required: true, trigger: 'blur', validator: validatePassword }
        ],
        captcha: [
          { required: true, trigger: 'blur', validator: validateCaptcha }
        ],
        name: [
          { required: true, trigger: 'blur', validator: validateName }
        ],
        idCard: [
          { required: true, trigger: 'blur', validator: validateIdCard }
        ],
        enterpriseName: [
          { required: true, trigger: 'blur', validator: validateEnterpriseName }
        ],
        creditCode: [
          { required: true, trigger: 'blur', validator: validateCreditCode }
        ],
        /* businessLicense: [
          { required: true, trigger: "blur", validator: validateBusinessLicense },
        ], */
        corporationName: [
          {
            required: true,
            trigger: 'blur',
            validator: validateCorporationName
          }
        ]
      }
    }
  },
  watch: {
    $route(to, from) {
      if (this.$route.query.type) {
        console.info('加载页面数据', to, from)
        this.type = this.$route.query.type
        // console.log(this.type);
      }
    }
  },
  mounted() {
    this.type = this.$route.query.type
    console.log(this.type)
    // console.log(this.type);
  },

  methods: {
    changePhone() {
      if (this.loginForm.phone && !isPhone(this.loginForm.phone)) {
        this.dis = false
      }
    },
    // 检查键盘是否打开了大写
    checkCapslock(e) {
      const { key } = e
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z'
    },
    // 提交登录
    handleLogin() {
      
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          if (this.type == 1) {
            // 个人注册
            this.loading = true
            const { phone, username, password, captcha, name, idCard } = this.loginForm

            const obj = {
              phone,
              userName: username,
              password,
              smsCode: captcha,
              name,
              idCard
              // timestamp: timestamp,
              // sign: sign
            }
            console.log(obj)
            // 后面编写提交到接口
            this.$store
              .dispatch('user/register', obj)
              .then((res) => {
                console.log(res)
                this.loading = false
                if (res.code === 0) {
                  /* this.$router.push({
                    // 注册成功跳转至首页
                    path: '/'
                  }) */
                  console.log('before');
                  this.$router.push({
                    path: `/myCenter/basicinfo?isEdit=1&phone=${phone}`
                  })
                  console.log('after');
                  setTimeout(() => {
                    location.reload()
                  }, 250)
                } else {
                  console.log('eee.....')
                }
              })
              .catch((err) => {
                //抛出error
                console.log(err)
                this.loading = false
              })
          } else {
            // 企业注册
            if (this.certificateImage === null) {
              this.$message({
                type: 'error',
                message: '请上传营业执照图片！'
              })
              return
            } else {
              this.loading = true
              const {
                phone,
                username,
                password,
                captcha,
                enterpriseName,
                creditCode,
                corporationName
              } = this.loginForm

              const obj = {
                phone,
                userName: username,
                password,
                smsCode: captcha,
                enterpriseName,
                creditCode,
                corporationName,
                businessLicense: this.certificateImage[0]
                // timestamp: timestamp,
                // sign: sign
              }
              console.log(obj)
              // 后面编写提交到接口
              this.$store.dispatch('user/entRegister', obj).then((res) => {
                console.log(res)
                this.loading = false
                if (res.code === 0) {
                  this.$router.push({
                    // 注册成功跳转至首页
                    path: '/'
                  })
                  setTimeout(() => {
                    location.reload()
                  }, 50)
                }
              })
            }
          }
          // register(obj).then(res=>{
          //   console.log(res);
          //   if(res.status === 200) {
          //     // console.log(res.data);
          //     this.$message({
          //       type: 'success',
          //       message: res.data.message
          //     })
          //   }else {
          //     this.$message.error(res.data.message)
          //   }
          //   this.loading = false
          // })
        }
      })
    },
    handlegetBtn() {
      if (this.loginForm.phone && !isPhone(this.loginForm.phone)) {
        let TIME_COUNT = 60
        if (!this.timer) {
          this.count = TIME_COUNT
          this.isGrey = true
          this.show = false
          this.dis = true
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--
            } else {
              this.dis = false
              this.isGrey = false
              this.show = true
              clearInterval(this.timer)
              this.timer = null
            }
          }, 1000)

          sendSMS({
            phone: this.loginForm.phone,
            smsType: 1
          }).then((res) => {
            if (res.status === 200) {
              // console.log(res.data);
              this.$message({
                type: 'success',
                message: res.data.message
              })
            } else {
              this.$message.error(res.data.message)
            }
            // console.log(res);
          })
        }
      } else {
        this.$message.error('手机号码不能为空！')
      }
    },
    // 获取图片地址
    getLogoList(val) {
      this.loginForm.img_url = val
      this.certificateImage = this.loginForm.img_url
    }
  }
}
</script>
  
  <style rel="stylesheet/scss" lang="scss" scoped>
.main {
  background: #f6f6f6;
  .location{
    margin: 20px auto 0;
    // border:1px #f00 solid;
    width: 1200px;
    text-align: left;
  }
  .wrapper {
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    margin-top: 25px;
  }
  .entry-container {
    height: auto;
    margin-top: 25px;
    border: 1px solid #ededed;
    box-sizing: border-box;
    background: #fff;
    .left-container {
      /* width: 600px; */
      min-height: 600px;
      padding: 10px;
      /* margin: 50px 0 0 0; */
      margin: 15px auto;
      box-sizing: border-box;
      /* float: left; */
      .layui-form {
        padding-left: 100px;
      }
      .layui-tab {
        margin: 10px 0;
        text-align: left !important;
      }

      .layui-tab-title {
        border: 0;
        position: relative;
        left: 0;
        height: 40px;
        white-space: nowrap;
        font-size: 0;
        border-color: #e6e6e6;
        li {
          display: inline-block;
          vertical-align: middle;
          font-size: 14px;
          position: relative;
          line-height: 40px;
          min-width: 65px;
          padding: 0 15px;
          text-align: center;
          cursor: pointer;
          list-style: none;
          a {
            display: block;
            border-bottom: 2px solid #0065b3;
            cursor: pointer;
            text-decoration: none;
            outline: none;
          }
        }
        .layui-this {
          color: #0065b3;
        }
      }
    }
    .right-container {
      min-height: 500px;
      padding: 10px;
      margin: 50px 0 0 0;
      box-sizing: border-box;
      float: right;
      border-left: 1px solid #dedede;
      text-align: center;
      .qr-item {
        margin-bottom: 20px;
      }
      .qr {
        img {
          width: 100%;
          height: 100%;
          margin-left: 16px;
        }
      }
    }
  }
  input {
    margin-top: 20px;
  }
  .mb20 {
    margin-bottom: 20px !important;
  }
  .shadow {
    box-shadow: 0 0 10px 8px #eaeced;
  }
  form {
    display: block;
  }
  .captcha {
    float: left;
    width: 140px;
  }
  .form_width {
    width: 600px;
    margin: 80px auto;
    .phone,
    .username,
    .password {
      float: left;
      width: 240px;
    }
    .w350 {
      width: 350px;
    }
    .btn-wrap {
      text-align: center;
      float: left;
      margin: 0 0 0 180px;
      .submit-btn {
        width: 240px;
      }
    }
    .img-tip{
      text-align:left;
      color:#999
    }
  }
}
</style>